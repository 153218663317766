import React, { Component, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link , useNavigate } from 'react-router-dom';
import globalVar from '../global';
import {Button, Collapse} from 'react-bootstrap';
import { useChangeInfoUserContext } from "../infoUserProvider";
import axios from 'axios';

const URI1 = process.env.REACT_APP_API_URL+'afiliada'
const URIsistema = process.env.REACT_APP_API_URL

const Navbar = () => {

  const borrarInfo=useChangeInfoUserContext()[5]
  
  const toggleOffcanvas=()=>{
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar=()=> {
    document.querySelector('.right-sidebar').classList.toggle('open');
  } 

  //const navigate = useHistory();
  const navigate = useNavigate();

  //metodo para navegar a la seleccion de empresa
  const handleEmpresaSeleccion = () =>{
    navigate('/seleccionEmpresaAgencia')
  }
  //Metodo para cerrar sesion
  const CerrarSesionHandler=async()=> {
    async function cambiarToken(){
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    await cambiarToken();
    await borrarInfo();
    globalVar.usuario='';
    window.location.reload()
  } 
  //Metodo para ir a configuraciones
  const configuracion = () =>{
    navigate('/configuracionUsuario')
  }

  //para visualizar a la imagen logo de la empresa afiliada
  const [imagen, setImagen] = useState('')
  const obtenerImagen = async() => {
    try {
      const img = await axios.post(URI1, 
        {'opcion': 1,
          'idAfiliada': window.localStorage.idEmpresa?window.localStorage.idEmpresa:'-1'
        } 
    )
      setImagen(img.data[0][0].rutaImagen);
    
    } catch (error) {
      console.log(error);
    }
  }

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  useEffect(()=>{ 
    var cookieToken = getCookie('token')
    if(cookieToken){
    obtenerImagen();
  }
  },[])

    return (
      <nav className="navbar p-0 fixed-top flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          {/* <Link to="/dashboard"><img src={require('../../assets/images/logo.png')} alt="logo" className="navbar-brand" /></Link> */}
            <div className='col-sm-12 ml-2 mt-2 d-flex'>
              <a href='/dashboard'>
              {imagen?(<img
                src={`${URIsistema}`+`uploads/imagenesAfiliadas/${imagen}`}
                alt="Logo de la empresa"
                style={{ maxWidth: '50px', maxHeight: '50px' }}
              />)
              :
              (<p>Sin logo disponible</p>)
              }
              </a>
              </div>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
  
          <ul className="navbar-nav navbar-nav-right">
        
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  {/*<img className="img-xs rounded-circle" src={require('../../assets/images/faces/face1.jpg')} alt="profile" />*/}
                  <p className="mb-0 d-flex d-sm-block navbar-profile-name">Hola {globalVar.usuario}</p>
                  <i className="mdi mdi-menu-down d-flex d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <h6 className="p-3 mb-0">Perfil</h6>
                <Dropdown.Divider />
                <Dropdown.Item onClick={configuracion} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Configuraciones</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="preview-item" onClick={handleEmpresaSeleccion}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi mdi-flattr text-warning"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Seleccionar Empresa</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={CerrarSesionHandler} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Cerrar Sesión</p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>
    );
  
}

export default Navbar;
